import { loadingElement } from "./utils/loadingElement";
import { httpRequest } from "./httpRequest";
import { updateList } from "./pagination";
export function userMessageAssignInit() {
  document.addEventListener("click", function (e) {
    if (!e.target.matches(".assign-message")) return;
    e.preventDefault();
    var route = e.target.dataset.route;
    // let id = e.target.dataset.id;
    var containerId = e.target.dataset.container;
    loadingElement.start(containerId);
    httpRequest({
      url: route
    }).then(function (response) {
      if (response && response.data && !response.data.error) {
        updateList({
          containerId: containerId
        });
      }
    })["finally"](function () {
      loadingElement.finish(containerId);
    });
  });
}